/* eslint-disable */

export function trackerLeadSquared() {
    pidTracker(50529);
}
export function trackerLeadSquaredEvent(n) {
    logMXWebEvent(50529,n);
}

export function getMXCProspectId(){
    if (typeof(MXCProspectId) !== "undefined")
    return MXCProspectId;
    else return null;
}

export function trackerFBPixel(event){
    fbq('track', event);
}
/* eslint-enable */
