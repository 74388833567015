import { createStore } from 'vuex'


const store = createStore({
   state:{
    
      multipllayer :3.75,
      displayc:"SAR",
      country:"",
   },
   mutations: {
     setCountry (state,c) {
       state.country = c;
     },
     setMultiplayer (state,d) {
        console.log("from store");
        console.log(d);
       
        state.displayc = d.currencyUse;
        state.multipllayer = d.displayMultiplier;
      }
   }
});

export default store